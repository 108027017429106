import { Pipe, PipeTransform } from "@angular/core";

/**
 * Remplacer une mot dans une chaine de caractere
 */
@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
  transform(text: string, searchValue: string, newValue: string) {

    //  console.log('text', text);
    // console.log('searchValue', searchValue);
    // console.log('newValue', newValue);
    let res = text.replace(searchValue, newValue);
    // console.log('res', res);

    return res;

  }
}
