import { Pipe, PipeTransform } from '@angular/core';

/**
 * Split d'un tableau en enlevant les []
 */
@Pipe({ name: 'split' })
export class SplitPipe implements PipeTransform {
  transform(text: string) {

    text = text.substring(0, (text.length) ); // Enlever les caracteres []
    let patternSMS = '@@@'; // Pattern de separation des entêtes
    let res = text.split(patternSMS);

    // Enlever les espaces dans les entetes
    for (const i in res) {
      if (res.hasOwnProperty(i)) {
        res[i] = res[i].trim();
      }
    }
    // console.log('pattern', res);
    return res;

  }
}
