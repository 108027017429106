import { LayoutclientService } from 'app/shared/services/layoutclient.service';
import { Component, OnInit, Input, OnDestroy, Renderer2, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { NavigationService } from '../../../shared/services/navigation.service';
import { Subscription } from 'rxjs';
import { ThemeService } from '../../../shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { Client } from 'app/shared/models/client.model';
import { NGXLogger } from 'ngx-logger';
import { AuthenticationService } from 'app/shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html'
})
export class HeaderTopComponent implements OnInit, OnDestroy {
  layoutConf: any;
  menuItems: any;
  menuItemSub: Subscription;
  egretThemes: any[] = [];
  currentLang = 'fr';
  availableLangs = [{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }]
  @Input() notificPanel;
  // @Input() updateSize;
  // @Output() valueChange = new EventEmitter();
  // counter = 0;


  public login: string;

  private _item;
  get updateSize(): any {
    return this._item;
  }

  @Input()
  set updateSize(val: any) {
    // this.logger.debug('previous item = ', this._item);
    this.logger.debug('currently selected item=', val);
    this._item = val;
    // this._item.status = 'In Process';
  }

  valueToDisplay: number = 0;


  constructor(
    private layout: LayoutclientService,
    private navService: NavigationService,
    public themeService: ThemeService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private logger: NGXLogger,
    private router: Router,
    public auth: AuthenticationService,
  ) { }

  

  ngOnChanges(changes: SimpleChanges) {
    const currentItem: SimpleChange = changes.updateSize;
    this.logger.debug('currentItem: ', changes);

    this.logger.debug('currentItem: ', currentItem);
    // this.logger.debug('prev value: ', currentItem.previousValue);
    this.logger.debug('got item: ', currentItem.currentValue);

    // this.sizeNotif += currentItem.currentValue;
    // this.valueChange.emit(14);
    // this.logger.debug('valueChange: ', this.valueChange);

    // this.logger.debug('sizeNotif: ', this.sizeNotif);

    this.logger.debug('ngchanges', this.updateSize);

    this.valueToDisplay = this.updateSize;




  }

  ngOnInit() {


    this.logger.debug('oninit', this.updateSize);
    // this.counter = this.counter + 1;
    //     this.valueChange.emit(this.counter);

    // Recuperer les infos du client
    let client: Client = JSON.parse(localStorage.getItem('SMSMarketing_currentCli'));
    this.login = client.clientlogin;
    let enableSender:boolean = client.clientcreatesenderenable

    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;
    this.menuItemSub = this.navService.menuItems$
    .subscribe(res => {
      
     
      
      res = res.filter(item => item.type !== 'icon' && item.type !== 'separator').filter(item => enableSender ?  true : !item.name.toLowerCase().includes('expediteurs')  ); // S'il a la possibilite de creer un sender, 
      let limit = 6;
      // console.log('BIG TAILLE', res.length);
      if (res.length > limit) {
        limit = 5;
      }
      let mainItems:any[] = res.slice(0, limit);
      if(res.length <= limit) {
        return this.menuItems = mainItems;
      }
      let subItems:any[] = res.slice(limit, res.length);
      mainItems.push({
        name: 'Autres',
        type: 'dropDown',
        tooltip: 'Plus de fonctionnalités',
        icon: 'more_horiz',
        sub: subItems
      })
      this.menuItems = mainItems;
    })
    
  }
  ngOnDestroy() {
    this.menuItemSub.unsubscribe();
  }
  setLang() {
    this.translate.use(this.currentLang);
  }
  changeTheme(theme) {
    this.themeService.changeTheme(this.renderer, theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  logout() {
    this.auth.removeCliToLocalStorage();
    this.router.navigate(['/sessions/user']);
  }
}
