import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigApp } from '../models/configApp.model';
import { Observable } from 'rxjs';
import { ConfigService } from 'ngx-envconfig';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient,
    private configService: ConfigService) {
  }

  private notificationUrl = this.configService.get('ip') + 'notification';

  public getNotif(usertype, userid): Observable<any> {
    return this.http.get(this.notificationUrl + '/' + 'findall' + '/' + usertype + '/' + userid, {
      observe: 'response'
    });  }

  public getAllNotifUnread(usertype, userid): Observable<HttpResponse<any>> {
    return this.http.get(this.notificationUrl + '/' + 'findunread' + '/' + usertype + '/' + userid, {
      observe: 'response'
    });
  }

  public getAllNotifRead(usertype, userid): Observable<HttpResponse<any>> {
    return this.http.get(this.notificationUrl + '/' + 'findread' + '/' + usertype + '/' + userid, {
      observe: 'response'
    });
  }

  public setNotifRead(notifId): Observable<HttpResponse<any>> {
    return this.http.get(this.notificationUrl + '/' + 'read' + '/' + notifId, {
      observe: 'response'
    });
  }

}
