import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'groupBy'})
export class GroupbyPipe implements PipeTransform {
    transform(collection: any, property: string, type?: string): any[] {
        // prevents the application from breaking if the array of objects doesn't exist yet
        if(!collection) {
            return null;
        }

        // console.log('property', property);
        // console.log('collection', collection);
        // console.log('type', type);



        const groupedCollection = collection.reduce((previous, current) => {


            // if(!previous[current[property]]) {
            //     previous[current[property]] = [current];
            // } else {
            //     previous[current[property]].push(current);
            // }


            switch (type) {
              case 'client':

                  if (!previous[current.client[property]]) {
                    previous[current.client[property]] = [current];
                  } else {
                      previous[current.client[property]].push(current);
                  }
                break;

                case 'revendeur':

                  if (!previous[current.revendeur[property]]) {
                    previous[current.revendeur[property]] = [current];
                  } else {
                      previous[current.revendeur[property]].push(current);
                  }
                break;
              default:
                  if(!previous[current[property]]) {
                        previous[current[property]] = [current];
                    } else {
                        previous[current[property]].push(current);
                    }
                break;
            }


            return previous;
        }, {});

        // this will return an array of objects, each object containing a group of objects
        // return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
        // const test =  Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
        // console.log('test', test);
        return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));

    }
}
