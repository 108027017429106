import { SoldeService } from 'app/shared/services/solde.service';
import { AppLoaderService } from './app-loader/app-loader.service';
import { MatSnackBar } from '@angular/material';
import { AppConfirmService } from 'app/shared/services/app-confirm/app-confirm.service';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Client } from '../models/client.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { ClientService } from './client.service';
import { TabventerevService } from './tabventerev.service';
import { TabventeclientService } from './tabventeclient.service';



@Injectable({
  providedIn: 'root'
})
export class AutreService {

   logoUser = new Subject<any>();


  constructor(
    private snack: MatSnackBar,
    private loader: AppLoaderService,
    private logger: NGXLogger,
    private clientService: ClientService,
    private soldeService: SoldeService,
    private tabventerevService: TabventerevService,
    private tabventeclientService: TabventeclientService
  ) { }

  public formatDate(str){
    return str.replace(" ", " ").replace("T", " ")
  }

  /**
   * 
   * Fonction pour obtenir la date courante
   * @param jour: nombre de jour à ajouter sur la date du jour
   */
  getCurrentDate(jour?: number): string {

    let timestampDate = Date.now();

    let currentDate = new Date(timestampDate);
    let localTime = currentDate.toLocaleTimeString('en-GB', { timeZone: 'UTC' });
    let indexMonth = currentDate.getMonth() + 1;
    let indexDay;
    if (jour) {
      // Convertir le nombre de jour en heure
      let heure = jour * 24;
      currentDate.setTime(currentDate.getTime() + heure * 3600 * 1000);
      // Recuperer la nouvelle date (mois et jour)
      indexDay = currentDate.getDate();
      indexMonth = currentDate.getMonth() + 1;

    } else {
      indexDay = currentDate.getDate();

    }

    let miliseconds = currentDate.getMilliseconds();
    //console.log(currentDate.getMilliseconds);

    let dateFinale = currentDate.getFullYear() + '-' + ((indexMonth<10)? '0'+indexMonth : indexMonth) + '-' + ((indexDay<10)? '0'+indexDay : indexDay ) + ' ' + localTime + '.' + miliseconds;
    const formattedCurrentDate = dateFinale;
    // console.log('formattedCurrentDate');
    // console.log(formattedCurrentDate);
    return this.formatDate(formattedCurrentDate);
  }


  get1960():string{
    return this.convertDateToString('1960-01-01')


  }

  /**
   * Fonction pour convertir une date en string
   * @param date
   * @param heure --- Defini si la fonction doit retourner la date avec les heures et millisecondes
   */
  convertDateToString(date: string, heure?: boolean, jour?: number): string {

    let dateRecu = new Date(date);

    console.log("==========  DTE RECU  =============");
    console.log(date);
    
    console.log(dateRecu);
    console.log("==========  DTE RECU  =============");
    
    let localTime = dateRecu.toLocaleTimeString('en-GB', { timeZone: 'UTC' });
    let indexMonth = dateRecu.getMonth() + 1;
    // let indexDay = dateRecu.getDate();
    let miliseconds = dateRecu.getMilliseconds();
    // console.log(currentDate.getMilliseconds);

    let indexDay;

    if (jour) {
      // Convertir le nombre de jour en heure
      let heure = jour * 24;
      dateRecu.setTime(dateRecu.getTime() + heure * 3600 * 1000);
      // Recuperer la nouvelle date (mois et jour)
      indexDay = dateRecu.getDate();
      indexMonth = dateRecu.getMonth() + 1;

    } else {
      indexDay = dateRecu.getDate();

    }


    if (!heure) {
      // let dateFinale = dateRecu.getFullYear() + '-' +((indexMonth<10)? '0'+indexMonth : indexMonth) + '-' + ((indexDay<10)? '0'+indexDay : indexDay ) + ' ' + localTime + '.' + miliseconds;
      let dateFinale = dateRecu.getFullYear() + '-' + ((indexMonth<10)? '0'+indexMonth : indexMonth) + '-' + ((indexDay<10)? '0'+indexDay : indexDay );
      const formattedCurrentDate = dateFinale;
      // console.log('formattedCurrentDate');
      // console.log(formattedCurrentDate);
      return formattedCurrentDate;
    }else {

      let dateFinale = dateRecu.getFullYear() + '-' + ((indexMonth<10)? '0'+indexMonth : indexMonth) + '-' + ((indexDay<10)? '0'+indexDay : indexDay ) + ' ' + dateRecu.toLocaleTimeString('en-GB', { timeZone: 'UTC' }) + '.' + dateRecu.getMilliseconds() ;
      const formattedCurrentDate = dateFinale;
      return formattedCurrentDate;
    }


  }



  /**
   * Compares two Date objects and returns e number value that represents
   * the result:
   * 0 if the two dates are equal.
   * 1 if the first date is greater than second. ou plus recente
   * -1 if the first date is less than second. ou plus ancienne
   * @param date1 First date object to compare.
   * @param date2 Second date object to compare.
   */
  public compareDate(date1: Date, date2: Date): number
  {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    let d1 = new Date(date1);
    let d2 = new Date(date2);

    // Check if the dates are equal
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;

    // Check if the first is greater than second
    if (d1 > d2) return 1;

    // Check if the first is less than second
    if (d1 < d2) return -1;
  }


  public getSoldeEtPrixSMSRevendeur(idrevendeur: number) {

    this.tabventerevService.getVenteRevendeurByRevendeurid(idrevendeur)
    .subscribe(data => {

      let statuscode = data.status;
      this.logger.trace('Autre service Obj prix', data.body);

      switch (statuscode) {
        case 200: {
          // this.loader.close();
          let donnees = data.body;

          // Assigner la valeur de la du solde ( (UT - UC)  )
          //localStorage.removeItem('SMSMarketing_currentRev');
          localStorage.setItem('SMSMarketing_currentRev', JSON.stringify(donnees[0].revendeur));

          // let revendeur: Revendeur = JSON.parse(localStorage.getItem('SMSMarketing_currentRev'));
          // let soldeRevendeur = Number(revendeur.revendeurut) - Number(revendeur.revendeuruc);
          // this.logger.trace('soldeRevendeur', soldeRevendeur);
          
          // send solde to subscribers via observable subject
          this.soldeService.sendSoldeRevendeur(data.body);


          break;
        }
        case 204: {
          // this.loader.close();
          //this.snack.open('No content', 'OK', { duration: 4000 })
          break;
        }
        case 404: {
          // this.loader.close();
          //this.snack.open('URL introuvable!', 'OK', { duration: 4000 })
          break;
        }
        case 500: {
          // this.loader.close();
          //this.snack.open("Erreur sur le serveur, Contacter l'administrateur de la plateforme", 'OK', { duration: 4000 })
          break;
        }
        default: {
          // this.loader.close();
          //this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
          break;
        }
      }
    },
    error => {
      let statuscode = error.status;
      this.logger.error('error', error);
      switch (statuscode) {
        case 404: {
          // this.loader.close();
          //this.snack.open('URL introuvable!', 'OK', { duration: 4000 })
          break;
        }
        case 500: {
          // this.loader.close();
          //this.snack.open("Erreur sur le serveur, Contacter l'administrateur de la plateforme", 'OK', { duration: 4000 })
          break;
        }
        default: {
          // this.loader.close();
          //this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
          break;
        }

      }

    } );
  }

  public getSoldeEtPrixSMSClient(idclient: number) {
    this.tabventeclientService.getVenteClientByClientid(idclient)
    .subscribe(data => {

      let statuscode = data.status;
      switch (statuscode) {
        case 200: {
          // this.loader.close();
          let donnees = data.body;

          // Assigner la valeur de la du solde ( (UT - UC) * prixvente )
          localStorage.removeItem('SMSMarketing_currentCli');
          localStorage.setItem('SMSMarketing_currentCli', JSON.stringify(donnees[0].client));
          this.logoUser.next(donnees[0].revendeur.revendeurlogo)
          // send solde to subscribers via observable subject
          this.soldeService.sendSolde(data.body);

          break;
        }
        case 204: {
          // this.loader.close();
          //this.snack.open('No content', 'OK', { duration: 4000 })
          break;
        }
        case 404: {
          // this.loader.close();
          //this.snack.open('URL introuvable!', 'OK', { duration: 4000 })
          break;
        }
        case 500: {
          // this.loader.close();
          //this.snack.open("Erreur sur le serveur, Contacter l'administrateur de la plateforme", 'OK', { duration: 4000 })
          break;
        }
        default: {
          // this.loader.close();
          //this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
          break;
        }
      }
    },
    error => {
      let statuscode = error.status;
      this.logger.error('error', error);
      switch (statuscode) {
        case 404: {
          // this.loader.close();
          //this.snack.open('URL introuvable!', 'OK', { duration: 4000 })
          break;
        }
        case 500: {
          // this.loader.close();
          //this.snack.open("Erreur sur le serveur, Contacter l'administrateur de la plateforme", 'OK', { duration: 4000 })
          break;
        }
        default: {
          // this.loader.close();
          //this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
          break;
        }

      }

    } );
  }


  public getSoldeEtPrixSMSClient2(idclient: number) {

    this.clientService.getClientById(idclient)
    .subscribe(data => {

      let statuscode = data.status;
      this.logger.trace(statuscode);

      switch (statuscode) {
        case 200: {
          let donnees = data.body as Client;
          localStorage.removeItem('SMSMarketing_currentCli');
          localStorage.setItem('SMSMarketing_currentCli', JSON.stringify(donnees));

          let client: Client = JSON.parse(localStorage.getItem('SMSMarketing_currentCli'));
          let soldeClient = Number(client.clientut) - Number(client.clientuc);
          this.logger.trace('soldeClient', soldeClient);

          // send solde to subscribers via observable subject
          this.soldeService.sendSolde(soldeClient.toString());

          break;
        }
        case 500: {
          this.loader.close();
          this.snack.open('Internal server error, rafraichisser votre solde SVP!', 'OK', { duration: 4000 })
          break;
        }
        default: {
          this.loader.close();
          this.snack.open('Une erreur est survenue rafraichisser votre solde SVP!', 'OK', { duration: 4000 })
          break;
        }
      }
    },
    error => {
      this.logger.error('error', error);

      let statuscode = error.status;
      switch(statuscode) {
        case 500: {
          this.snack.open('Internal server error, rafraichisser votre solde SVP!', 'OK', { duration: 4000 })
          break;
        }
        default: {
          this.snack.open('Une erreur est survenue rafraichisser votre solde SVP!', 'OK', { duration: 4000 })
          break;
        }

      }

    } );
  }


  public resultStatus (statuscode: number, message: string): boolean {

    switch(statuscode) {

      case 200: {
        this.loader.close();
        this.snack.open('SMS en cours d\'envoi!', 'OK', { duration: 4000 });
        return true;
      }
      case 201: {
        this.loader.close();
        this.snack.open('SMS en cours d\'envoi!', 'OK', { duration: 4000 });
        return true;
      }
      case 204: {
        this.loader.close();
        this.snack.open('SMS en cours d\'envoi!', 'OK', { duration: 4000 });
        return true;
      }
      case 404: {
        this.loader.close();
        this.snack.open('URL introuvable!', 'OK', { duration: 4000 });
        return false;
      }
      case 401: {
        this.loader.close();
        this.snack.open('Unauthorized, One or several parameters are not informed', 'OK', { duration: 4000 });
        return false;
      }
      case 406: {
        this.loader.close();
        this.snack.open('Not Acceptable, Incorrect Format of the parameters', 'OK', { duration: 4000 });
        return false;
      }
      case 409: {
        this.loader.close();
        this.snack.open('Conflict, Object Already exist', 'OK', { duration: 4000 });
        return false;
      }
      case 500: {
        this.loader.close();
        this.snack.open("Erreur sur le serveur, Contacter l'administrateur de la plateforme", 'OK', { duration: 4000 });
        return false;
      }
      default: {
        this.loader.close();
        this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 });
        return false;
      }
    }

  }

      /**
 * Return an
 * @param {DOMElement} el A dom element of a textarea or input text.
 * @return {Object} reference Object with 2 properties (start and end) with the identifier of the location of the cursor and selected text.
 **/
 getInputSelection(el) {
  const doc = document as any;

  var start = 0, end = 0, normalizedValue, range,
      textInputRange, len, endRange;

  if (typeof el.selectionStart == 'number' && typeof el.selectionEnd == 'number') {
      start = el.selectionStart;
      end = el.selectionEnd;
  } else {
      range = doc.selection.createRange();

      if (range && range.parentElement() == el) {
          len = el.value.length;
          normalizedValue = el.value.replace(/\r\n/g, "\n");

          // Create a working TextRange that lives only in the input
          textInputRange = el.createTextRange();
          textInputRange.moveToBookmark(range.getBookmark());

          // Check if the start and end of the selection are at the very end
          // of the input, since moveStart/moveEnd doesn't return what we want
          // in those cases
          endRange = el.createTextRange();
          endRange.collapse(false);

          if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
              start = end = len;
          } else {
              start = -textInputRange.moveStart("character", -len);
              start += normalizedValue.slice(0, start).split("\n").length - 1;

              if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                  end = len;
              } else {
                  end = -textInputRange.moveEnd("character", -len);
                  end += normalizedValue.slice(0, end).split("\n").length - 1;
              }
          }
      }
  }

  return {
      start: start,
      end: end
  };
}


}
