import { MatSnackBar } from '@angular/material';
import { AppLoaderService } from './../../../shared/services/app-loader/app-loader.service';
import { Subscription } from 'rxjs';
import { Params } from '@angular/router';
import { ResultatPaiement } from './../../../shared/models/resultatPaiement.model';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { RequestPaiementService } from './request-paiement.service';

@Component({
  selector: 'app-request-paiement',
  templateUrl: './request-paiement.component.html',
  styleUrls: ['./request-paiement.component.scss']
})
export class RequestPaiementComponent implements OnInit, OnDestroy {

  role: string;
  resultatPaiement: ResultatPaiement = new ResultatPaiement();
  requestPaiementSub: Subscription;
  id_customer: string;
  id_cart: string;
  isAdmin: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router,
    private logger: NGXLogger,
    private requestPaiement: RequestPaiementService,
    private loader: AppLoaderService,
    private snack: MatSnackBar

  ) { }

  ngOnInit() {
    this.logger.trace('BEGIN');

    this.activatedRoute.queryParams.forEach((params: Params) => {
        this.role = params['role'];
        this.resultatPaiement.tid = params['tid'];
        this.resultatPaiement.status = params['status'];
        this.resultatPaiement.achat = params['achat'];
        this.id_cart = params['idcart'];
        this.id_customer = params['idcustomer'];

        this.logger.trace('params', params);
      });


      if (this.role == 'administrateurs' || this.role == 'users') { // Si achat revendeur
        this.updatePaiement(this.role, this.resultatPaiement, this.id_customer);
      } else { // Si role acheteur non detecté
        // TODO: Retour au login utilisateur
        this.route.navigate(['/sessions/user']);
      }
  }

  ngOnDestroy(): void {
    if (this.requestPaiementSub) {
      this.requestPaiementSub.unsubscribe();
    }
  }

  /**
   * Fonction pour mettre à jour la BD de SMS Marketing
   * @param role
   * @param resultatPaiement
   * @param id_customer
   */
  updatePaiement(role: string, resultatPaiement: ResultatPaiement, id_customer: string) {
    this.logger.trace('Params entrants', role, resultatPaiement);
    if (this.role == 'administrateurs') {
      this.isAdmin = true;
    } else if (this.role == 'users') {
      this.isAdmin = false;
    } else {
      this.isAdmin = false;
    }
    this.requestPaiementSub = this.requestPaiement.updatePaiement(id_customer, resultatPaiement, this.isAdmin)
      .subscribe(data => {

        let statuscode = data.status;
        this.logger.trace(statuscode);

        switch (statuscode) {
          case 200: {
            this.snack.open('Mise à jour reussi, reconnexion...', 'OK', { duration: 8000 });
            if (this.role == 'administrateurs') {
              this.route.navigate(['/administrateurs/achat']);

            } else if (this.role == 'users') {
              this.route.navigate(['/users/achatcredit']);

            } else {
              this.route.navigate(['/sessions/user']);

            }
            break;
          }
          case 204: {
            this.loader.close();
            //this.snack.open('No content', 'OK', { duration: 4000 })
            break;
          }
          case 404: {
            this.loader.close();
            //this.snack.open('URL introuvable!', 'OK', { duration: 4000 })
            break;
          }
          case 500: {
            this.loader.close();
            //this.snack.open("Erreur sur le serveur, Contacter l'administrateur de la plateforme", 'OK', { duration: 4000 })
            break;
          }
          default: {
            this.loader.close();
            //this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 })
            break;
          }
        }
      },
      error => {
        let statuscode = error.status;
        this.logger.error('error', error);
        this.loader.close();
        switch(statuscode) {
          case 406: {
            this.snack.open('Achat introuvable pour la mise à jour, reconnexion', 'OK', { duration: 8000 });
            if (this.role == 'administrateurs') {
              this.route.navigate(['/administrateurs/achat']);

            } else if (this.role == 'users') {
              this.route.navigate(['/users/achatcredit']);

            } else {
              this.route.navigate(['/sessions/user']);

            }
            break;
          }
          default: {
            this.snack.open('Erreur de mise à jour, reconnexion', 'OK', { duration: 8000 });
            if (this.role == 'administrateurs') {
              this.route.navigate(['/administrateurs/achat']);

            } else if (this.role == 'users') {
              this.route.navigate(['/users/achatcredit']);

            } else {
              this.route.navigate(['/sessions/user']);

            }
            break;
          }

        }

      } );
  }



}
