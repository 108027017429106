import { Notifications } from './../../models/notifications.model';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription, } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Client } from 'app/shared/models/client.model';
import { NotificationService } from 'app/shared/services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {

  public getItemSub: Subscription;
  @Input() notificPanel;
  @Output() sizeEmmit = new EventEmitter();
  client: Client = new Client();
  usertype = 'client';
  size = 0;

  // notifications: Notifications  = new Notifications();
  public notifications: Notifications[];


  // Dummy notifications
  // notifications2 = [{
  //   message: 'Ajout',
  //   icon: 'assignment_ind',
  //   time: '1 min ago',
  //   route: '/clients/dashboard',
  //   color: 'primary'
  // }, {
  //   message: 'Nouveau message',
  //   icon: 'chat',
  //   time: '4 min ago',
  //   route: '/clients/dashboard',
  //   color: 'accent'
  // }, {
  //   message: 'Restauration',
  //   icon: 'settings_backup_restore',
  //   time: '12 min ago',
  //   route: '/clients/dashboard',
  //   color: 'warn'
  // }]

  constructor(private router: Router,
              private logger: NGXLogger,
              private notificationService: NotificationService) {}

  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
        if (routeChange instanceof NavigationEnd) {
          this.notificPanel.close();
        }
    });


    // Recuperer les infos du client
    this.client = JSON.parse(localStorage.getItem('SMSMarketing_currentCli'));
    this.getAllNotifUnread(this.usertype, this.client.clientid);

  }
  clearAll(e) {
    //TODO: Marqué les notifs comme lues
    e.preventDefault();
    // this.notifications = [];
    this.logger.trace('1,' , this.notifications);

    for(let i in this.notifications) {
      // this.notifications[i];
      // this.logger.trace('notif', i, this.notifications[i].notificationid);

      this.setNotifRead(this.notifications[i].notificationid);

    }

  }

  ngOnDestroy() {
    if (this.getItemSub) {
      this.getItemSub.unsubscribe();
    }
  }

  getAllNotifUnread(usertype, clientid) {
      this.getItemSub = this.notificationService.getAllNotifUnread(usertype, clientid)
      .subscribe(data => {

        let statuscode = data.status;
        // console.log(statuscode);
        this.logger.trace(statuscode);



        switch (statuscode) {
          case 200: {
            this.notifications = data.body;
            this.size = this.notifications.length;
            this.sizeEmmit.emit(this.size);
            this.logger.debug(this.notifications);
            this.logger.debug(this.size);
            break;
          }
          case 204: {
            this.notifications = [];
            this.sizeEmmit.emit(0);

            break;
          }
          case 404: {
            // this.snack.open('URL introuvable!', 'OK', { duration: 4000 });
            break;
          }
          case 500: {
            // this.snack.open("Erreur sur le serveur, Contacter l'administrateur de la plateforme", 'OK', { duration: 4000 });
            break;
          }
          default: {
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 });
            break;
          }
        }
      },
      error => {
        let statuscode = error.status;
        switch(statuscode) {
          case 404: {
            this.logger.error('error', error);
            // this.snack.open('URL introuvable!', 'OK', { duration: 4000 });
            break;
          }
          case 500: {
            this.logger.error('error', error);
            // this.snack.open("Erreur sur le serveur, Contacter l'administrateur de la plateforme", 'OK', { duration: 4000 });
            break;
          }
          default: {
            this.logger.error('error', error);
            // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 });
            break;
          }

        }

      } );
  }

  setNotifRead(notifId) {
    this.getItemSub = this.notificationService.setNotifRead(notifId)
    .subscribe(data => {

      let statuscode = data.status;
      // console.log(statuscode);
      this.logger.trace(statuscode);



      switch (statuscode) {
        case 200: {
          // this.notifications = data.body;
          // this.size = this.notifications.length;
          // console.log(this.notifications);
          this.getAllNotifUnread(this.usertype, this.client.clientid);

          break;
        }
        case 204: {
          this.notifications = [];
          break;
        }
        case 404: {
          // this.snack.open('URL introuvable!', 'OK', { duration: 4000 });
          break;
        }
        case 500: {
          // this.snack.open("Erreur sur le serveur, Contacter l'administrateur de la plateforme", 'OK', { duration: 4000 });
          break;
        }
        default: {
          // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 });
          break;
        }
      }
    },
    error => {
      let statuscode = error.status;
      switch(statuscode) {
        case 404: {
          this.logger.error('error', error);
          // this.snack.open('URL introuvable!', 'OK', { duration: 4000 });
          break;
        }
        case 500: {
          this.logger.error('error', error);
          // this.snack.open("Erreur sur le serveur, Contacter l'administrateur de la plateforme", 'OK', { duration: 4000 });
          break;
        }
        default: {
          this.logger.error('error', error);
          // this.snack.open('Une erreur est survenue!', 'OK', { duration: 4000 });
          break;
        }

      }

    } );
}

}
