import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import {AuthrootsGuard} from './shared/services/auth/authroots.guard';
import { RevendeurLayoutComponent } from './shared/components/layouts/revendeur-layout/revendeur-layout.component';
import { ClientLayoutComponent } from './shared/components/layouts/client-layout/client-layout.component';
import { AuthcliGuard } from './shared/services/authcli.guard';
import { AuthrevGuard } from './shared/services/authrev.guard';
import { RequestPaiementComponent } from './views/paiement/request-paiement/request-paiement.component';


export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'users/sms/smsenvoyer',
    pathMatch: 'full'
  },
  // Achat de credit
  {
    path: 'requestpaiement',
    component: RequestPaiementComponent,
    data: { title: 'Enregistrement Statut Paiement'}
  },
  // Authentification - Sessions
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: './views/sessions/sessions.module#SessionsModule',
        data: { title: 'Session'}
      }
    ]
  },
       
    // ACTEUR CLIENT
    {
    path: 'users',
    component: ClientLayoutComponent,
    canActivate: [AuthcliGuard],
      children: [
        {
          path: '',
          loadChildren: './views/client/client.module#ClientModule',
          data: { title: 'Client', breadcrumb: 'Client'}
        },
        {
          path: 'sms',
          loadChildren: './views/client/sms/sms.module#SmsModule',
          data: { title: 'SMS', breadcrumb: 'SMS'}
        },
      ]
    },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

