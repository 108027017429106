import { Pipe, PipeTransform } from '@angular/core';

/**
 * Prendre la partie entiere du nombre
 */
@Pipe({ name: 'trunc' })
export class TruncPipe implements PipeTransform {
  transform(text: string) {

    let res = Math.trunc( Number(text) );
    return res;

  }
}
