import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthcliGuard implements CanActivate {

  public authToken;

  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // console.log(" verify => "+localStorage.getItem('SMSMarketing_currentCli'));
    if (localStorage.getItem('SMSMarketing_currentCli')!=null) {
      return true
    }
    this.router.navigate(['/sessions/user']);
    return false;
  }
}
