import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class SoldeService {

  private subject = new Subject<any>();
  private subjectRevendeur = new Subject<any>();


  constructor(
    private logger: NGXLogger
    ) {}

  sendSolde(solde: string) {
    // this.logger.trace('solde service', solde);
      this.subject.next(solde);
  }

  sendSoldeRevendeur(solde: string) {
    // this.logger.trace('solde service', solde);
      this.subjectRevendeur.next(solde);
  }

  clearSolde() {
      this.subject.next();
  }

  clearSoldeRevendeur() {
    this.subjectRevendeur.next();
}

  getSolde(): Observable<any>  {
      return this.subject.asObservable();
  }

  getSoldeRevendeur(): Observable<any>  {
    return this.subjectRevendeur.asObservable();
}

}
