import { Alerte } from './alerte.model';

export class Client {

  clientid: number;
  clientdatecreation: any;
  clientdatenaissance: any;
  clientemail: string;
  clientenable: boolean;
  clientlastconnexion: any;
  clientlogin: string;
  clientname: string;
  clientpassword: string;
  clientstatus: string;
  clienttel: string;
  clientuc: number;
  clientut: number;
  clienttoken: string;
  clientsecurekey: string;
  alerte: Alerte;
  clientcreatesenderenable:boolean;
  clientcallbackurl: string;

}
