import { Component, OnInit, AfterViewInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { RoutePartsService } from './shared/services/route-parts.service';
import { ThemeService } from './shared/services/theme.service';

import { filter } from 'rxjs/operators';
import { HopscotchService } from 'ngx-hopscotch';
import { NGXLogger } from 'ngx-logger';
import { NgxHopscotchModule } from 'ngx-hopscotch';
import { environment } from '../environments/environment.prod';
import { VersionCheckService } from './shared/services/version-check.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'Prestige Sms';
  pageTitle = '';

  constructor(
    public title: Title,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private themeService: ThemeService,
    private hopscotchService: HopscotchService,
    private logger: NGXLogger,
    private renderer: Renderer2,
    private versionCheckService : VersionCheckService,
  ) {}

  ngOnInit() {
    this.changePageTitle();
    this.configureTour();
    this.versionCheckService.initVersionCheck(environment.versionCheckURL);
  }

  ngAfterViewInit() {
    this.themeService.applyMatTheme(this.renderer);
    // this.hopscotchService.init();
  }

  public ngOnDestroy(): void {
    // this.hopscotchService.end();
  }



  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length)
        return this.title.setTitle(this.appTitle);
      // Extract title from parts;
      this.pageTitle = routeParts
                      .reverse()
                      .map((part) => part.title )
                      .reduce((partA, partI) => {return `${partA} > ${partI}`});
      this.pageTitle += ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }

  configureTour() {
    this.logger.trace('Begin Tour AppComponent');

    this.hopscotchService.configure([
      {
        stepIndex: 0,
        stepDef: {
          target: 'one',
          placement: 'left',
          content: "Le contenu.",
          title: "Bienvenue su PRESTIGE-SMS",
          multipage: true,
        }
      },
      {
        stepIndex: 1,
        stepDef: {
          target: 'two',
          placement: 'left',
          content: "This step was triggered from the prior step's onNext callback. The next step's target doesn't exist yet until you click the Next button.",
          title: "Titre2",
          multipage: true
        }
      },
      {
        stepIndex: 2,
        stepDef: {
          target: 'three',
          placement: 'left',
          content: "This step was triggered from the prior step's onNext callback. This is the last step, but you can click the Back button to return to the previous step.",
          title: "Titre3",
        }
      }
    ]);
  }


}
