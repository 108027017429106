export const environment = {
  production: true,
  apiURL: 'productionApi',
  state: 'production',
  serverLoggingUrl: 'https://kyrmannpay.com:9097/coresmsmarketing/angularlogger/add',

  // IP server KGS
  ip : 'https://kyrmannpay.com:9097/coresmsmarketing/',
  // ip : 'http://192.168.1.2:9097/coresmsmarketing/',

  adresseip : 'http://5.189.171.167:9097/',

  pathSMSMarketing : 'https://kyrmannpay.com/smsmarketing/#/',

  loginKyrmann : 'KYRMANN',

  // IP localhost
  // ip : 'http://localhost:9097/coresmsmarketing/',

  // URL des achats revendeur
  urlAchat : 'https://kyrmannpay.com/smsmarketing/#/revendeurs/achat/?achat=ok/',
  // urlAchat : 'http://localhost:4200/#/revendeurs/achat/?achat=ok/',

  // URL des achats client
  urlAchatClient : 'https://kyrmannpay.com/smsmarketing/#/clients/achatcredit/?achat=ok/',
  // urlAchatClient : 'http://localhost:4200/#/clients/achatcredit/?achat=ok/',

  // URL pour mettre à jour le paiement revendeur SMSmarketing
  curlPaiement : 'https://kyrmannpay.com:9097/coresmsmarketing/revendeur/achetercredit/',
  // curlPaiement : 'http://192.168.1.13:9097/coresmsmarketing/revendeur/achetercredit/',

  // URL pour mettre à jour le paiement client SMSmarketing
  curlPaiementClient : 'https://kyrmannpay.com:9097/coresmsmarketing/client/achetercredit/',
  // curlPaiement : 'http://192.168.1.13:9097/coresmsmarketing/revendeur/achetercredit/',


  // Afid
  afid : 126126,

  // user sur africapay
  user : 'smsmarketing',

  // password sur africapay
  pass : '$2a$12$3vQmuhxiJfV2REV7J9E/KeWE51SDqdhHytJh7i6hCkbowpUkdcNRS',


   // IP test user
   // ip : 'http://192.168.1.10:9097/coresmsmarketing/',

   // Nombre max de mot pour un message
   caractereMax : 459,

   // Nombre max de page pour un message
   nombrePageMax : 3,

   // Contact Kyrmann
   KyrmannContact1 : '+225 22 00 27 15',
   KyrmannContact2 : '+225 01 00 02 77',
   KyrmannContact3 : '+225 04 48 48 79',
   KyrmannEmail : 'hello@kyrmann.com',
   KyrmannBP : '06 BP 6696 Abidjan 06',

   versionCheckURL : `https://prestige-sms.com/version.json`,

};
