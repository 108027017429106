import { Pipe, PipeTransform } from "@angular/core";

/**
 * Affichr un aperçu du champ
 */
@Pipe({ name: 'smsresume' })
export class SmsresumePipe implements PipeTransform {
  transform(text: string,val?:number) {

     let max = val ? val : 24;
     let taille = text.length;
     let res = text;
     if (taille > max ) {
      res = text.substring(0, max) + ' ...';

     }

    return res;

  }
}
