import { ConfigApp } from 'app/shared/models/configApp.model';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs/Rx';
import { ConfigService } from 'ngx-envconfig';
import { Tabventerev } from '../models/tabventerev';
import { Operateur } from '../models/operateur';
import { Revendeur } from '../models/revendeur';


const httpOptions = {
  headers: new HttpHeaders({'Access-Control-Allow-Origin' : '*'})
    .set("content-Type" , "application/json")
};


@Injectable({
  providedIn: 'root'
})
export class TabventerevService {



  private  ulrfortabventerev = this.configService.get('ip') + 'venterevendeur';
  private  ulrforoperateur = this.configService.get('ip') + 'operateur';
  private  ulrforrevendeur= this.configService.get('ip') + 'revendeur';




  constructor(private http: HttpClient, private configService: ConfigService) { }

  public createVenteRev(venterev : Tabventerev, rootlogin: string): Observable<HttpResponse<any>> {
    let body = JSON.stringify(venterev);
    // console.log(body);
    return this.http.post(this.ulrfortabventerev + '/' + 'add' + '/' + rootlogin, body, {
      observe: 'response',
      headers: new HttpHeaders({'Access-Control-Allow-Origin' : '*'})
        .set("content-Type" , "application/json")
    });
  }

  public updateVenteRev(venterev : Tabventerev, rootlogin: string): Observable<HttpResponse<any>> {
    let body = JSON.stringify(venterev);
    // console.log("update url : "+this.ulrfortabventerev + '/' + 'update' + '/' + rootlogin);
    // console.log(body);
    return this.http.put(this.ulrfortabventerev + '/' + 'update' + '/' + rootlogin, body, {
      observe: 'response',
      headers: new HttpHeaders({'Access-Control-Allow-Origin' : '*'})
        .set("content-Type" , "application/json")
    });
  }

  public deleteVenteRev(venterev : Tabventerev): Observable<HttpResponse<any>> {
    return this.http.delete(this.ulrfortabventerev + '/' + 'delete/' + venterev.venterevendeurid, {
      observe: 'response'
    });
  }

  getallTabventerev() : Observable<any[]>{
    return this.http.get<Tabventerev[]>(this.ulrfortabventerev + '/' + 'getall' ,httpOptions);
  }

  getallOperateur() : Observable<Operateur[]>{
    return this.http.get<Operateur[]>(this.ulrforoperateur + '/' + 'getall' ,httpOptions);
  }

  getallRevendeur() : Observable<Revendeur[]>{
    return this.http.get<Revendeur[]>(this.ulrforrevendeur + '/' + 'getall' ,httpOptions);
  }

  public getTabventerevById(revendeurid: number): Observable<HttpResponse<any>> {
    return this.http.get(this.ulrfortabventerev + '/' + 'findbyid' + '/' + revendeurid , {
      observe: 'response'
    });
  }

  public getVenteRevendeurByRevendeurid(idRevendeur: number): Observable<HttpResponse<any>> {
    return this.http.get(this.ulrfortabventerev + '/' + 'getbyrevendeurid' + '/' + idRevendeur, {
      observe: 'response'
    });
  }

}
