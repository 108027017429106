import { IMenuItem } from './../../../services/navigation.service';
import { SoldeService } from './../../../services/solde.service';
import { Input, SimpleChanges, SimpleChange, OnChanges, DoCheck } from '@angular/core';
import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationService } from 'app/shared/services/navigation.service';
import { ThemeService } from 'app/shared/services/theme.service';
import { Revendeur } from 'app/shared/models/revendeur';
import { AutreService } from 'app/shared/services/autre.service';
import { ConfigService } from 'ngx-envconfig';

@Component({
  selector: 'app-revendeur-side',
  templateUrl: './revendeur-side.component.html',
  styleUrls: ['./revendeur-side.component.scss'],
  outputs: ['change'],
})
export class RevendeurSideComponent implements OnInit, DoCheck {


  revendeurut: number;
  revendeuruc: number;
  soldeRevendeur: any;

  soldePrixRevendeur: any;

  prixSMS: number;
  prixSMSBF: number;
  nbreSMS: number;

  donnees: any[];

  revendeur: Revendeur;

  loginKyrmann = this.configService.get('loginKyrmann');

  revendeurid: number;
  // private sidebarPS: PerfectScrollbar;
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;






  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private soldeService: SoldeService,
    private autreService: AutreService,
    private configService: ConfigService,
    
  ) { }


  ngDoCheck() {
    if (this.revendeur) {
      this.obtainMenu(this.revendeur.revendeurrole); // Charger le menu (Kyrmann, admin)
    }

    //OBTENIR LE SOLDE DU REVENDEUR
    this.soldeService.getSoldeRevendeur().subscribe(data => {
      let obj = [];
      for (const key of data) {
        if ( key.operateur.operateurstatus === 'active' && key.operateur.operateurenable === true) {
          obj.push(key);
        }
      }
      this.soldePrixRevendeur = obj;
      this.soldeRevendeur = Math.trunc(data[0].revendeur.revendeurut - data[0].revendeur.revendeuruc); // Rafraichir le solde

      });
  }




  ngOnInit() {

    // Activer le menu
    console.log('Chargement du menu');
    
    // Recuperer les informations du revendeur du revendeur
    this.revendeur = JSON.parse(localStorage.getItem('SMSMarketing_currentRev'));

    this.obtainMenu(this.revendeur.revendeurrole); // Charger le menu (Kyrmann, admin)

    this.autreService.getSoldeEtPrixSMSRevendeur(this.revendeur.revendeurid); // Mise à jour du solde revendeur

    this.revendeurid = this.revendeur.revendeurid;

  }




  
  /**
   * Afficher le menu correspondant au role de l'utilisateur connecté
   * le revendeur et kyrman ont le mm menu donc la verification est un if 
   * avec pour condition 'admin'
   * @param revendeurrole
   */
  obtainMenu(revendeurrole: string) {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;

    if(revendeurrole != "admin") { // Verifier si l'utilisateur KYRMANN est connecté

      this.menuItemsSub = this.navService.menuItemsRev$.subscribe(menuItem => {
        this.menuItems = menuItem;
        //Checks item list has any icon type.
        this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.type === 'icon').length;
      });
          // this.navigation.publishNavigationChange('revendeurMenu');
    } else {
      // this.menuItems = this.navigation.replaceItemMenu('revendeurMenu', 2, 1, this.adminMenu);
        this.menuItemsSub = this.navService.menuItemsAdmin$.subscribe(menuItem => {
          this.menuItems = menuItem;
          //Checks item list has any icon type.
          this.hasIconTypeMenuItem = !!this.menuItems.filter(item => item.type === 'icon').length;
        });
    }
}





  ngOnDestroy() {
    // if(this.sidebarPS) {
    //   this.sidebarPS.destroy();
    // }
    if(this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }




  /**
   * Mise à jour du solde revendeur
   * @param idrevendeur
   */

  public getSoldeEtPrixSMS(idrevendeur: number) {
    this.autreService.getSoldeEtPrixSMSRevendeur(idrevendeur);

  }

}
