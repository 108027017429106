import { Component, OnInit, EventEmitter, Input, Output, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from 'app/shared/services/theme.service';
import { LayoutService } from 'app/shared/services/layout.service';
import { Revendeur } from 'app/shared/models/revendeur';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/shared/services/authentication.service';

@Component({
  selector: 'app-headerrevendeur-side',
  templateUrl: './headerrevendeur-side.component.html'
})
export class HeaderrevendeurSideComponent implements OnInit {

  @Input() notificPanel;
  currentLang = 'fr';
  public availableLangs = [{
    name: 'Français',
    code: 'fr',
  },{
    name: 'English',
    code: 'en',
  }, {
    name: 'Spanish',
    code: 'es',
  }]
  public egretThemes;
  public layoutConf:any;
  public login : string ;

  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public translate: TranslateService,
    private renderer: Renderer2,
    private router: Router,
    public auth: AuthenticationService,
  ) {}
  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
    // this.translate.use(this.currentLang);
    let revendeur: Revendeur = JSON.parse(localStorage.getItem('SMSMarketing_currentRev'));
    this.login=revendeur.revendeurlogin;
  }
  setLang(e) {
    // console.log(e)
    // this.translate.use(this.currentLang);
  }
  changeTheme(theme) {
    this.themeService.changeTheme(this.renderer, theme);
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  toggleCollapse() {
    // compact --> full
    if(this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      }, {transitionClass: true})
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact'
    }, {transitionClass: true})

  }

  logout() {
    this.auth.removeRevToLocalStorage();
    this.router.navigate(['/sessions/user']);
  }

}
