import { FormGroup, ValidatorFn, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';

import { isString } from 'util';

/** comparer 2 champs */
export function comparaisonValidator(targetKey: string, toMatchKey: string): ValidatorFn {

  return (group: FormGroup): {[key: string]: any} => {
    const target = group.controls[targetKey];
    const toMatch = group.controls[toMatchKey];

    if (target.touched || toMatch.touched || target.pristine) {
      // console.log('Entrer');
  // console.log(target.value);
  // console.log(toMatch.value);
      const isMatch = target.value <= toMatch.value;
      // console.log(isMatch);

      // set equal value error on dirty controls
      if (!isMatch && target.valid && toMatch.valid) {
        toMatch.setErrors({equalValue: targetKey});
        const message = targetKey + ' <= ' + toMatchKey;
        return {'equalValue': message};
      }
      if (isMatch && toMatch.hasError('equalValue')) {
        toMatch.setErrors(null);
      }
    }

    return null;
  };
}

/** Verifier si champs est vide */
export function nullValidator(targetKey: string): ValidatorFn {
  return (group: FormGroup): {[key: string]: any} => {
    const target = group.controls[targetKey];
    if (target.touched || target.pristine) {
      const isMatch = target.value.trim() != '';
      // set equal value error on dirty controls
      if (!isMatch && target.valid ) {
        target.setErrors({equalValue: targetKey});
        const message = targetKey + ' null ' ;
        return {'nullValue': message};
      }
      if (isMatch && target.hasError('nullValue')) {
        target.setErrors(null);
      }
    }

    return null;
  };
}


export class CustomNullValidators {
  static required(control: AbstractControl): ValidationErrors {
       return (isString(control.value) && control.value.trim() == "") ?
             {'required': true} :
             null;
  }
}

export function nullConfirmation(alertenamekey: string) {
  return (group: FormGroup) => {
    let alertename= group.controls[alertenamekey];
    let chaine = alertename.value.trim();
    if (chaine === '') {
      return alertename.setErrors({mismatchedPasswords: true})
    }
  }
}



export function NoWhitespaceValidator(): ValidatorFn {

  return (control: AbstractControl): { [key: string]: any } => {
    // console.log('VALIDATOR ');

     // messy but you get the idea
    let isWhitespace = (control.value || '').trim().length === 0;
    let isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': 'value is only whitespace' }

  };

}




