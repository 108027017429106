import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm-color',
  template: `<h1 matDialogTitle>{{ data.title }}</h1>
    <div mat-dialog-content><font color="red">{{ data.message }}</font></div>
    <div mat-dialog-actions>
    <button
    type="button"
    mat-raised-button
    color="primary"
    (click)="dialogRef.close(true)">OK</button>
    &nbsp;
    <span fxFlex></span>
    <button
    type="button"
    color="accent"
    mat-raised-button
    (click)="dialogRef.close(false)">Annuler</button>
    </div>`,
})
export class AppComfirmColorComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmColorComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any
  ) {}
}
