import { SoldeService } from 'app/shared/services/solde.service';
import { Client } from './../../../models/client.model';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { Component, OnInit, AfterViewInit, ViewChild, HostListener, DoCheck } from '@angular/core';
import {
  Router,
  NavigationEnd,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  ResolveStart,
  ResolveEnd,
  ActivatedRoute
} from '@angular/router';
import { Subscription } from 'rxjs';
import { MatSidenav, MatSnackBar } from '@angular/material';
import { MediaChange, ObservableMedia } from '@angular/flex-layout';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../../../services/theme.service';
// import PerfectScrollbar from 'perfect-scrollbar';
import { filter } from 'rxjs/operators';
import { NavigationService } from '../../../services/navigation.service';
import { LayoutclientService } from 'app/shared/services/layoutclient.service';
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { AutreService } from 'app/shared/services/autre.service';

@Component({
  selector: 'app-client-layout',
  templateUrl: './client-layout.component.html',
  styleUrls: ['./client-layout.component.scss']
})

export class ClientLayoutComponent implements OnInit, DoCheck, AfterViewInit {

  // solde: number;
  newSizeNotif;

  clientid: number;
  prixSMS: number;

  public isModuleLoading: Boolean = false;
  private moduleLoaderSub: Subscription;
  private layoutConfSub: Subscription;
  private routerEventSub: Subscription;
  private mediaSub: Subscription;
  soldeSubscription: Subscription;
  soldeClient: any;
  nbreSMS: number;

  soldePrixClient: any;


  donnees: any[];
  loginrev: string = '';

  loginKyrmann = this.configService.get('loginKyrmann');

  revendeurmontantclientenable: boolean = false;
  revendeurnombresmsclientenable: boolean = true;

  // private sidebarPS: PerfectScrollbar;

  // private bodyPS: PerfectScrollbar;
  // private headerFixedBodyPS: PerfectScrollbar;
  public  scrollConfig = {}
  public layoutConf: any = {};
  dispLogo: boolean = false;
  logoSrc: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public themeService: ThemeService,
    private layout: LayoutclientService,
    private media: ObservableMedia,
    private navigation: NavigationService,
    private loader: AppLoaderService,
    private logger: NGXLogger,
    private soldeService: SoldeService,
    private snack: MatSnackBar,
    private configService: ConfigService,
    private autreService: AutreService

  ) {
    // Close sidenav after route change in mobile
    this.routerEventSub = router.events.pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((routeChange: NavigationEnd) => {
      this.layout.adjustLayout({ route: routeChange.url });
    });

    // Translator init
    // const browserLang: string = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

  }

  ngDoCheck() {
    // this.logger.trace('ngDoCheck');
    this.soldeService.getSolde().subscribe(data => {
      // this.logger.trace('data', data);
      // this.soldeClient = Math.trunc(data.solde);
      // this.getSoldeEtPrixSMS(this.clientid);

     // console.log("-----------------------SOLDE", data);
      

      // this.logger.trace('data observable', data);
      // this.soldePrixClient = data;
      let obj = [];
      for (const key of data) {
        // this.logger.trace('KEY', key.operateur.operateurstatus);
        if ( key.operateur.operateurstatus === 'active' && key.operateur.operateurenable === true) {
          obj.push(key);
        }
      }
      this.soldePrixClient = obj;
      // this.logger.trace('OBJ FINAL SANS LES STATUTS INACTIFS', this.soldePrixClient);
      this.soldeClient = Math.trunc(data[0].client.clientut - data[0].client.clientuc); // Rafraichir le solde

      // Verifier si le solde et/ou le nombre de SMS doit etre afficher
      this.revendeurmontantclientenable = data[0].revendeur.revendeurmontantclientenable;
      this.revendeurnombresmsclientenable = data[0].revendeur.revendeurnombresmsclientenable;
      //role du revendeur
      let revendeurrole = data[0].revendeur.revendeurrole;
      this.loginrev = data[0].revendeur.revendeurlogin;
      // this.logger.trace('Login du revendeur', this.loginrev, info);
      if (this.loginrev.toUpperCase() == this.loginKyrmann) {
        // Charger menu du client lambda
        // this.logger.trace('charger menu kyrmann client');
        let user = JSON.parse(localStorage.getItem("SMSMarketing_currentCli"))
        if (user.clientispostpaid) {
          this.navigation.publishNavigationChange('clientKyrmannPostpaidMenu');
        } else {
          this.navigation.publishNavigationChange('clientKyrmannMenu');
        }
        
         // console.log("clientKyrmannMenu");

      } else {
        if (revendeurrole !== "admin") {
          this.navigation.publishNavigationChange('clientRevMenu');
        }
      }
      
      
      


      });

      this.autreService.logoUser.subscribe(logo => {
        if (logo) {
          this.dispLogo = true;
          this.logoSrc = logo.replace("/var/www/html","https://kyrmannpay.com") 
        }else {
          this.dispLogo = false;
        }
      })
  }

  ngOnInit() {

    //Activer le menu
    // console.log('Charger menu');
    this.navigation.publishNavigationChange('clientMenu');


    this.layoutConf = this.layout.layoutConf;
    // this.layout.adjustLayout();
    // console.log(this.layoutConf);
    this.logger.trace(this.layoutConf);

    // FOR MODULE LOADER FLAG
    this.moduleLoaderSub = this.router.events.subscribe(event => {
      if(event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.isModuleLoading = true;
      }
      if(event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.isModuleLoading = false;
      }
    });

    // GetSolde
    // Recuperer UT du client
    let client: Client = JSON.parse(localStorage.getItem('SMSMarketing_currentCli'));
    this.clientid = client.clientid;
    // this.getSoldeEtPrixSMS(this.clientid);
    this.autreService.getSoldeEtPrixSMSClient(this.clientid); // Mise à jour du solde client
    //alert(this.dispLogo)
    


  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.layout.adjustLayout(event);
  }

  ngAfterViewInit() {

    // this.layoutConfSub = this.layout.layoutConf$.subscribe(change => {
    //   // this.initBodyPS(change)
    // })
  }

  // initBodyPS(layoutConf:any = {}) {
  //   if(layoutConf.navigationPos === 'side' && layoutConf.topbarFixed) {
  //     if (this.bodyPS) this.bodyPS.destroy();
  //     if (this.headerFixedBodyPS) this.headerFixedBodyPS.destroy();
  //     this.headerFixedBodyPS = new PerfectScrollbar('.rightside-content-hold', {
  //       suppressScrollX: true
  //     });
  //     this.scrollToTop('.rightside-content-hold');
  //   } else {
  //     if (this.bodyPS) this.bodyPS.destroy();
  //     if (this.headerFixedBodyPS) this.headerFixedBodyPS.destroy();
  //     this.bodyPS = new PerfectScrollbar('.main-content-wrap', {
  //       suppressScrollX: true
  //     });
  //     this.scrollToTop('.main-content-wrap');
  //   }
  // }
  scrollToTop(selector: string) {
    if(document) {
      let element = <HTMLElement>document.querySelector(selector);
      element.scrollTop = 0;
    }
  }
  ngOnDestroy() {

    if(this.moduleLoaderSub) {
      this.moduleLoaderSub.unsubscribe();
    }
    if(this.layoutConfSub) {
      this.layoutConfSub.unsubscribe();
    }
    if(this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
    if(this.soldeSubscription) {
      this.soldeSubscription.unsubscribe();
    }
  }
  closeSidebar() {
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  // Obtenir la nombre de notifications non lues
  obtainSize(size) {
    this.logger.trace('size layout');
    this.logger.trace('size', size);
    this.newSizeNotif = size ;
  }

  /**
   * Fonction de mise à jour du solde
   * @param idclient
   */
  public getSoldeEtPrixSMS(idclient: number) {
    this.autreService.getSoldeEtPrixSMSClient(idclient); // Mise à jour du solde client

  }


}
