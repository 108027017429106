import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ConfigService } from 'ngx-envconfig';
import { Injectable } from '@angular/core';
import { ResultatPaiement } from 'app/shared/models/resultatPaiement.model';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class RequestPaiementService {

  private achatUpdateUserUrl = this.configService.get('ip') + 'client/achetercredit';
  private achatUpdateAdminUrl = this.configService.get('ip') + 'revendeur/achetercredit';


  constructor(
    private http: HttpClient, private configService: ConfigService,
    private logger: NGXLogger
  ) {}


  public updatePaiement(customerid: string, resultatPaiement: ResultatPaiement, isAdmin: boolean): Observable<HttpResponse<any>> {
    this.logger.trace('SERVICE', customerid, isAdmin, resultatPaiement);

    if (isAdmin) {
      return this.http.post(this.achatUpdateAdminUrl + '/' + customerid , resultatPaiement, {
        observe: 'response'
      });

    } else {

      return this.http.post(this.achatUpdateUserUrl + '/' + customerid , resultatPaiement, {
        observe: 'response'
      });

    }
  }

}
