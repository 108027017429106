import { NgxHopscotchModule } from 'ngx-hopscotch';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import {
  MatSidenavModule,
  MatListModule,
  MatTooltipModule,
  MatOptionModule,
  MatSelectModule,
  MatMenuModule,
  MatSnackBarModule,
  MatGridListModule,
  MatToolbarModule,
  MatIconModule,
  MatButtonModule,
  MatRadioModule,
  MatCheckboxModule,
  MatCardModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatDialogModule,
  MatProgressBarModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatChipsModule,
  MatInputModule
} from '@angular/material';

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from './components/header-side/header-side.component';
import { HeaderrevendeurSideComponent } from './components/header-side/headerrevendeur-side/headerrevendeur-side.component';
import { SidebarSideComponent } from './components/sidebar-side/sidebar-side.component';
import { RevendeurSideComponent } from './components/sidebar-side/revendeur-side/revendeur-side.component';



// ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT
import { HeaderTopComponent } from './components/header-top/header-top.component';
import { SidebarTopComponent } from './components/sidebar-top/sidebar-top.component';

// ONLY FOR DEMO (Removable without changing any layout configuration)
import { CustomizerComponent } from './components/customizer/customizer.component';

// ALL TIME REQUIRED
import { AdminLayoutComponent } from './components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './components/layouts/auth-layout/auth-layout.component';
import { RevendeurLayoutComponent } from './components/layouts/revendeur-layout/revendeur-layout.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AppComfirmComponent } from './services/app-confirm/app-confirm.component';
import { AppComfirmColorComponent } from './services/app-confirm/app-confirm-color.component';
import { AppLoaderComponent } from './services/app-loader/app-loader.component';
import { AppProgressbarComponent } from './services/app-progressbar/app-progressbar.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactclientComponent } from './components/contact/contactclient/contactclient.component';


// DIRECTIVES
import { FontSizeDirective } from './directives/font-size.directive';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { AppDropdownDirective } from './directives/dropdown.directive';
import { DropdownAnchorDirective } from './directives/dropdown-anchor.directive';
import { DropdownLinkDirective } from './directives/dropdown-link.directive';
import { EgretSideNavToggleDirective } from './directives/egret-side-nav-toggle.directive';
import { NowhitespaceDirective } from './directives/nowhitespace.directive';


// PIPES
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { ExcerptPipe } from './pipes/excerpt.pipe';
import { GetValueByKeyPipe } from './pipes/get-value-by-key.pipe';
import { ChangeValuePipe } from './pipes/change-value.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { SmsresumePipe } from './pipes/smsresume.pipe';
import { SplitPipe } from './pipes/split.pipe';
import { TruncPipe } from './pipes/trunc.pipe';
import { GroupbyPipe } from './pipes/groupby.pipe';

// SERVICES
import { ThemeService } from './services/theme.service';
import { LayoutService } from './services/layout.service';
import { NavigationService } from './services/navigation.service';
import { RoutePartsService } from './services/route-parts.service';
import { AuthGuard } from './services/auth/auth.guard';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';
import { AppConfirmColorService } from './services/app-confirm/app-confirm-color.service';
import { AppLoaderService } from './services/app-loader/app-loader.service';
import { ClientLayoutComponent } from './components/layouts/client-layout/client-layout.component';
import { LayoutclientService } from './services/layoutclient.service';
import { AppProgressbarService } from './services/app-progressbar/app-progressbar.service';
import { NotificationService } from './services/notification.service';
// import { SoldeService } from './services/solde.service';



/*
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/
// import { LandingPageService } from '../shared/services/landing-page.service';

const classesToInclude = [
  HeaderTopComponent,
  SidebarTopComponent,
  SidenavComponent,
  NotificationsComponent,
  SidebarSideComponent,
  RevendeurSideComponent,
  HeaderSideComponent,
  HeaderrevendeurSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  RevendeurLayoutComponent,
  ClientLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppComfirmColorComponent,
  AppLoaderComponent,
  AppProgressbarComponent,
  CustomizerComponent,
  ContactComponent,
  ContactclientComponent,
  FontSizeDirective,
  ScrollToDirective,
  AppDropdownDirective,
  DropdownAnchorDirective,
  DropdownLinkDirective,
  EgretSideNavToggleDirective,
  NowhitespaceDirective,
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  ChangeValuePipe,
  ReplacePipe,
  SmsresumePipe,
  SplitPipe,
  TruncPipe,
  GroupbyPipe
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule,
    MatSidenavModule,
    MatListModule,
    MatTooltipModule,
    MatOptionModule,
    MatSelectModule,
    MatMenuModule,
    MatSnackBarModule,
    MatGridListModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatRippleModule,
    MatDialogModule,
    PerfectScrollbarModule,



    // AppModule,
    // RevendeurModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    MatInputModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatFormFieldModule,
  ],
  entryComponents: [AppComfirmComponent,AppComfirmColorComponent, AppLoaderComponent, AppProgressbarComponent],
  providers: [
    ThemeService,
    LayoutService,
    LayoutclientService,
    NavigationService,
    RoutePartsService,
    AuthGuard,
    AppConfirmService,
    AppConfirmColorService,
    AppLoaderService,
    AppLoaderService,
    AppProgressbarService,
    NotificationService,
    // SoldeService
    // LandingPageService
  ],
  declarations: classesToInclude,
  exports: classesToInclude
})
export class SharedModule { }
