import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';
import { NoWhitespaceValidator } from '../models/comparaisonValidator';

@Directive({
  selector: '[appNowhitespace]',
  providers: [{ provide: NG_VALIDATORS, useExisting: NowhitespaceDirective, multi: true }]

})
export class NowhitespaceDirective implements Validator {

  private valFn = NoWhitespaceValidator();
  validate(control: AbstractControl): { [key: string]: any } {
    // console.log('DIRECTIVE ');
    // this.logger.trace('DIRECTIVE ');

      return this.valFn(control);
  }

}
