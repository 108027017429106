import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'ngx-envconfig';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  isCustomizerOpen: boolean = false;

  // Const pour les contacts
  KyrmannContact1 = this.configService.get('KyrmannContact1');
  KyrmannContact2 = this.configService.get('KyrmannContact2');
  KyrmannContact3 = this.configService.get('KyrmannContact3');
  KyrmannContactBP = this.configService.get('KyrmannBP');
  KyrmannEmail = this.configService.get('KyrmannEmail');


  constructor(
    private configService: ConfigService
  ) {}

  ngOnInit() {

  }

  telechargerDoc() {
    window.open(this.configService.get('urlDoc'));
  }


}
