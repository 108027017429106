import { Pipe, PipeTransform } from "@angular/core";

/**
 * Rechercher une valeur et remplacer dans une chaine de caractere
 */
@Pipe({ name: 'changeValue' })
export class ChangeValuePipe implements PipeTransform {
  transform(text: string, compareValue: string, newValue: string) {
    // console.log('text', text);
    // console.log('compareValue', compareValue);
    // console.log('newValue', newValue);

    if (text.toLocaleUpperCase() == compareValue.toLocaleUpperCase()) {
      return newValue;
    } else {
      return text;

    }
  }
}
