import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
  type: string,       // Possible values: link/dropDown/icon/separator/extLink
  name?: string,      // Used as display text for item and title for separator type
  state?: string,     // Router state
  icon?: string,      // Material icon name
  tooltip?: string,   // Tooltip text
  disabled?: boolean, // If true, item will not be appeared in sidenav.
  sub?: IChildItem[], // Dropdown items
  badges?: IBadge[]
}
export interface IChildItem {
  type?: string,
  name: string,       // Display text
  state?: string,     // Router state
  icon?: string,
  sub?: IChildItem[]
}

export interface IBadge {
  color: string;      // primary/accent/warn/hex color codes(#fff000)
  value: string;      // Display text
}

@Injectable()
export class NavigationService {
  constructor() { }

  rootMenu: IMenuItem[] = [
    {
      name: 'Tableau de bord',
      type: 'link',
      tooltip: 'Tableau de bord',
      icon: 'dashboard',
      state: 'dashboard'
    },
    {
      name: 'Administrateur',
      type: 'dropDown',
      tooltip: 'Administrateur',
      icon: 'how_to_reg',
      state: '',
      sub: [
        { name: 'Administrateur', state: 'revendeur', icon: 'dashboard' },
        { name: 'Ventes', state: 'prixrevendeur' }
      ]
    },
    {
      name: 'Achat',
      type: 'dropDown',
      tooltip: 'Achat',
      icon: 'account_balance_wallet',
      state: '',
      sub: [
        { name: 'Administrateur', state: 'achat', icon: 'account_balance_wallet' },
        { name: 'Utilisateur', state: 'achatclient', icon: 'account_balance_wallet' }
      ]
    },
    {
      name: 'Operateur',
      type: 'link',
      tooltip: 'Operateur',
      icon: 'speaker_phone',
      state: 'operateur'
    },
    {
      name: 'Carrier',
      type: 'link',
      tooltip: 'Carrier',
      icon: 'router',
      state: 'carrier'
    },
    {
      name: 'Pays',
      type: 'link',
      tooltip: 'Pays',
      icon: 'flag',
      state: 'pays'
    },
    {
      name: 'Indice',
      type: 'link',
      tooltip: 'Indice',
      icon: 'sim_card',
      state: 'indice'
    },
    {
      name: 'Sendername',
      type: 'link',
      tooltip: 'Expediteurs',
      icon: 'people',
      state: 'root/sendername'
    },
    {
      name: 'Historique',
      type: 'dropDown',
      tooltip: 'Historique',
      icon: 'history',
      state: 'historique',
      sub: [
        { name: 'Utilisateur', state: 'client' },
        { name: 'Administrateur', state: 'revendeur' },
        { name: 'SMS', state: 'smsenvoye' },
        { name: 'Carrier', state: 'carrier' },
        { name: 'Operateur', state: 'operateur' },
        { name: 'Table vente', state: 'tablevente' },
      ]
    }/*,
    {
      name: 'Statistique',
      type: 'dropDown',
      tooltip: 'Statistique',
      icon: 'stars',
      state: 'statistique',
      sub: [
        { name: 'Client', state: 'client' },
        { name: 'Revendeur', state: 'revendeur' },
        { name: 'Carrier', state: 'carrier' },
        { name: 'Operateur', state: 'operateur' },
        { name: 'Table vente', state: 'tablevente' },
      ]
    }*/

  ]
  clientMenu: IMenuItem[] = [
    {
      name: 'TABLEAU DE BORD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'users/dashboard'
    },
    {
      name: 'Envoi de SMS',
      type: 'link',
      tooltip: 'Envoi de SMS',
      icon: 'textsms',
      state: 'users/sms/envoi',
    },
    {
      name: 'Expediteurs',
      type: 'link',
      tooltip: 'Expediteurs',
      icon: 'people',
      state: 'users/sendername'
    },
    {
      name: 'Repertoire',
      type: 'link',
      tooltip: 'Repertoire',
      icon: 'folder_shared',
      state: 'users/repertoire'
    },
    {
      name: 'Finances',
      type: 'link',
      tooltip: 'Finances',
      icon: 'account_balance_wallet',
      state: 'users/achatcredit'
    },
    {
      name: 'Alerte ',
      type: 'link',
      tooltip: 'Calendar',
      icon: 'date_range',
      state: 'users/alerte'
    },
    {
      name: 'Factures ',
      type: 'link',
      tooltip: 'Factures',
      icon: 'list',
      state: 'users/gestion-factures'
    },
    {
      name: 'FAQ ',
      type: 'link',
      tooltip: 'FAQ',
      icon: 'list',
      state: 'users/faq'
    },
   
    {
      name: 'Historique',
      type: 'link',
      tooltip: 'Historique',
      icon: 'history',
      state: 'users/historique'
    }, {
      name: 'Prix par operateur',
      type: 'link',
      tooltip: 'Destination',
      icon: 'flight_takeoff',
      state: 'users/destination'
    },
    {
      name: "Clé d'envoi SMS",
      type: 'link',
      tooltip: 'Token key',
      icon: 'vpn_key',
      state: 'users/smskey'
    }

  ]

  clientRevMenu: IMenuItem[] = [
    {
      name: 'TABLEAU DE BORD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'users/dashboard'
    },
    {
      name: 'Envoi de SMS',
      type: 'link',
      tooltip: 'Envoi de SMS',
      icon: 'textsms',
      state: 'users/sms/envoi',
    },
    {
      name: 'Expediteurs',
      type: 'link',
      tooltip: 'Expediteurs',
      icon: 'people',
      state: 'users/sendername'
    },
    {
      name: 'Repertoire',
      type: 'link',
      tooltip: 'Repertoire',
      icon: 'folder_shared',
      state: 'users/repertoire'
    },
    {
      name: 'FAQ ',
      type: 'link',
      tooltip: 'FAQ',
      icon: 'list',
      state: 'users/faq'
    },
    {
      name: 'Alerte ',
      type: 'link',
      tooltip: 'Calendar',
      icon: 'date_range',
      state: 'users/alerte'
    },
    {
      name: 'Historique',
      type: 'link',
      tooltip: 'Historique',
      icon: 'history',
      state: 'users/historique'
    }, {
      name: 'Prix par operateur',
      type: 'link',
      tooltip: 'Destination',
      icon: 'flight_takeoff',
      state: 'users/destination'
    },
    {
      name: "Clé d'envoi SMS",
      type: 'link',
      tooltip: 'Token key',
      icon: 'vpn_key',
      state: 'users/smskey'
    }

  ]
  clientKyrmannMenu: IMenuItem[] = [
    {
      name: 'TABLEAU DE BORD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'users/dashboard'
    },
    {
      name: 'Envoi de SMS',
      type: 'link',
      tooltip: 'Envoi de SMS',
      icon: 'textsms',
      state: 'users/sms/envoi',
    },
    {
      name: 'Expediteurs',
      type: 'link',
      tooltip: 'Expediteurs',
      icon: 'people',
      state: 'users/sendername'
    },
    {
      name: 'Repertoire',
      type: 'link',
      tooltip: 'Repertoire',
      icon: 'folder_shared',
      state: 'users/repertoire'
    },
    {
      name: 'Finances',
      type: 'link',
      tooltip: 'Finances',
      icon: 'account_balance_wallet',
      state: 'users/achatcredit'
    },
    {
      name: 'Alerte ',
      type: 'link',
      tooltip: 'Calendar',
      icon: 'date_range',
      state: 'users/alerte'
    },
    {
      name: 'FAQ ',
      type: 'link',
      tooltip: 'FAQ',
      icon: 'list',
      state: 'users/faq'
    },
    {
      name: 'Historique',
      type: 'link',
      tooltip: 'Historique',
      icon: 'history',
      state: 'users/historique'
    }, {
      name: 'Prix par destination',
      type: 'link',
      tooltip: 'Destination',
      icon: 'flight_takeoff',
      state: 'users/destination'
    },
    {
      name: 'Clé d\'envoi SMS',
      type: 'link',
      tooltip: 'Token key',
      icon: 'vpn_key',
      state: 'users/smskey'
    }

  ]
  clientKyrmannPostpaidMenu: IMenuItem[] = [
    {
      name: 'TABLEAU DE BORD',
      type: 'link',
      tooltip: 'Dashboard',
      icon: 'dashboard',
      state: 'users/dashboard'
    },
    {
      name: 'Envoi de SMS',
      type: 'link',
      tooltip: 'Envoi de SMS',
      icon: 'textsms',
      state: 'users/sms/envoi',
    },
    {
      name: 'Expediteurs',
      type: 'link',
      tooltip: 'Expediteurs',
      icon: 'people',
      state: 'users/sendername'
    },
    {
      name: 'Repertoire',
      type: 'link',
      tooltip: 'Repertoire',
      icon: 'folder_shared',
      state: 'users/repertoire'
    },
    {
      name: 'Finances',
      type: 'link',
      tooltip: 'Finances',
      icon: 'account_balance_wallet',
      state: 'users/achatcredit'
    },
    {
      name: 'Factures ',
      type: 'link',
      tooltip: 'Factures',
      icon: 'receipt',
      state: 'users/gestion-factures'
    },
    {
      name: 'FAQ ',
      type: 'link',
      tooltip: 'FAQ',
      icon: 'list',
      state: 'users/faq'
    },
    {
      name: 'Alerte ',
      type: 'link',
      tooltip: 'Calendar',
      icon: 'date_range',
      state: 'users/alerte'
    },
    {
      name: 'Historique',
      type: 'link',
      tooltip: 'Historique',
      icon: 'history',
      state: 'users/historique'
    }, {
      name: 'Prix par destination',
      type: 'link',
      tooltip: 'Destination',
      icon: 'flight_takeoff',
      state: 'users/destination'
    },
    {
      name: 'Clé d\'envoi SMS',
      type: 'link',
      tooltip: 'Token key',
      icon: 'vpn_key',
      state: 'users/smskey'
    }

  ]
  revendeurMenu: IMenuItem[] = [
    {
      name: 'Menu',
      type: 'separator'
    },
    {
      name: 'Tableau de bord',
      type: 'link',
      tooltip: 'Tableau de bord',
      icon: 'dashboard',
      state: 'administrateurs/dashboard'
    },
    // {
    //   name: 'Expediteurs',
    //   type: 'link',
    //   tooltip: 'Expediteurs',
    //   icon: 'people',
    //   state: 'administrateurs/sendername'
    // },
    {
      name: 'Utilisateur',
      type: 'dropDown',
      tooltip: 'Utilisateur',
      icon: 'face',
      state: '',
      sub: [
        { name: 'Utilisateur', state: 'administrateurs/client' },
        { name: 'Ventes', state: 'administrateurs/venteclient' },
      ]
    },
    {
      name: 'Finances',
      type: 'link',
      tooltip: 'Achat',
      icon: 'account_balance_wallet',
      state: 'administrateurs/achat'
    },
    {
      name: 'Historique',
      type: 'dropDown',
      tooltip: 'Historique',
      icon: 'history',
      state: '',
      sub: [
        { name: 'Mon historique', state: 'administrateurs/historique' },
        { name: 'Utilisateur', state: 'administrateurs/historiqueclient' },
        { name: 'Table vente', state: 'administrateurs/historiquetablevente' },
      ]
    },
    {
      name: 'Prix par destination',
      type: 'link',
      tooltip: 'Destination',
      icon: 'flight_takeoff',
      state: 'administrateurs/destination'
    },
    {
      name: 'Documentation',
      type: 'link',
      tooltip: 'Documentation',
      icon: 'library_books',
      state: 'administrateurs/documentation'
    }

  ]




  adminMenu: IMenuItem[] = [
    {
      name: 'Menu',
      type: 'separator'
    },
    {
      name: 'Tableau de bord',
      type: 'link',
      tooltip: 'Tableau de bord',
      icon: 'dashboard',
      state: 'administrateurs/dashboard'
    },
    {
      name: 'Expediteurs',
      type: 'link',
      tooltip: 'Expediteurs',
      icon: 'people',
      state: 'administrateurs/sendername'
    },
    {
      name: 'Utilisateur',
      type: 'link',
      tooltip: 'Utilisateur',
      icon: 'face',
      state: 'administrateurs/client',
    },
    {
      name: 'Finances',
      type: 'link',
      tooltip: 'Achat',
      icon: 'account_balance_wallet',
      state: 'administrateurs/achat'
    },
    {
      name: 'Historique',
      type: 'dropDown',
      tooltip: 'Historique',
      icon: 'history',
      state: '',
      sub: [
        { name: 'Mon historique', state: 'administrateurs/historique' },
        { name: 'Utilisateur', state: 'administrateurs/historiqueclient' },
        { name: 'Table vente', state: 'administrateurs/historiquetablevente' },
      ]
    },
    {
      name: 'Prix par destination',
      type: 'link',
      tooltip: 'Destination',
      icon: 'flight_takeoff',
      state: 'administrateurs/destination'
    },
    {
      name: 'Documentation',
      type: 'link',
      tooltip: 'Documentation',
      icon: 'library_books',
      state: 'administrateurs/documentation'
    }
  ]



  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.rootMenu);
  menuItemsRev = new BehaviorSubject<IMenuItem[]>(this.revendeurMenu);
  menuItemsAdmin = new BehaviorSubject<IMenuItem[]>(this.adminMenu);


  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  menuItemsRev$ = this.menuItemsRev.asObservable();
  menuItemsAdmin$ = this.menuItemsAdmin.asObservable();


  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case 'clientMenu':
        this.menuItems.next(this.clientMenu);
        break;
      case 'revendeurMenu':
        this.menuItems.next(this.revendeurMenu);
        break;
      case 'adminMenu':
        this.menuItems.next(this.adminMenu);
        break;
      case 'clientKyrmannMenu':
        this.menuItems.next(this.clientKyrmannMenu);
        break;
      case 'clientRevMenu':
        this.menuItems.next(this.clientRevMenu);
        break;
      case 'clientKyrmannPostpaidMenu':
        this.menuItems.next(this.clientKyrmannPostpaidMenu);
        break;
      default:
        this.menuItems.next(this.rootMenu);
    }
  }


}
