import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Venteclient } from '../models/venteclient.model';
import { LoggerConfig, NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { startWith } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class TabventeclientService {

  items: any[];

  private venteclientUrl = this.configService.get('ip') + 'venteclient';
  private venteclientUrl2 = this.configService.get('ip') + 'client';

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private configService: ConfigService
  ) {  }



  // TEST
  getItems(): Observable<any> {
    //return  of(this.items);
    return  of(this.items.slice());
  }


public getVenteClient(): Observable<HttpResponse<any>> {
  return this.http.get(this.venteclientUrl + '/' + 'getall', {
    observe: 'response'
  });
}

public getVenteClientByRevendeurid(idRevendeur: number): Observable<HttpResponse<any>> {
  return this.http.get(this.venteclientUrl + '/' + 'findbyrevendeurid' + '/' + idRevendeur, {
    observe: 'response'
  });
}

public getVenteClientByClientid(idClient: number): Observable<HttpResponse<any>> {
  return this.http.get(this.venteclientUrl + '/' + 'findbyclientid' + '/' + idClient, {
    observe: 'response'
  });
}

public addVenteCredit(venteclientid: number, newUt: number): Observable<HttpResponse<any>> {
  return this.http.get(this.venteclientUrl2 + '/' + 'addcredit/' + venteclientid + '/' + newUt, {
    observe: 'response'
  });
}
public delCredit(venteclientid: number, newUt: number): Observable<HttpResponse<any>> {
  return this.http.get(this.venteclientUrl2 + '/' + 'cutcredit/' + venteclientid + '/' + newUt,{
    observe: 'response'
  });
}

  public createVenteClient(venteclient: Venteclient): Observable<HttpResponse<any>> {
    return this.http.post(this.venteclientUrl + '/' + 'add', venteclient, {
      observe: 'response'
    });
  }

  public updateVenteClient(venteclient: Venteclient): Observable<HttpResponse<any>> {
    this.logger.trace(venteclient);
    return this.http.put(this.venteclientUrl + '/' + 'update', venteclient, {
      observe: 'response'
    });
  }

  public deleteVenteClient(venteclient: Venteclient): Observable<HttpResponse<any>> {
    return this.http.delete(this.venteclientUrl + '/' + 'delete/' + venteclient.venteclientid, {
      observe: 'response'
    });
  }
}
