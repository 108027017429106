import { Input, Output, EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { ConfigService } from 'ngx-envconfig';
import { Client } from '../models/client.model';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  items: any[];
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private logger: NGXLogger
  ) {
    /*let clientDB = new ClientDB();
    this.items = clientDB.clients;*/
  }

  private _listners = new Subject<any>();

  private clientUrl = this.configService.get('ip') + 'client';


  // TEST
  getItems(): Observable<any> {
    //return  of(this.items);
    return  of(this.items.slice());
  }


public getClient(): Observable<HttpResponse<any>> {
  return this.http.get(this.clientUrl + '/' + 'getall', {
    observe: 'response'
  });
}

public getClientById(clientid: number): Observable<HttpResponse<any>> {
  return this.http.get(this.clientUrl + '/' + 'findbyid' + '/' + clientid, {
    observe: 'response'
  });
}

public getClientByRevendeurid(idRevendeur: number): Observable<HttpResponse<any>> {
  return this.http.get(this.clientUrl + '/' + 'getallclient' + '/' + idRevendeur, {
    observe: 'response'
  });
}

public addCredit(clientid: number, newUt: number): Observable<HttpResponse<any>> {
  return this.http.get(this.clientUrl + '/' + 'addcredit/' + clientid + '/' + newUt, {
    observe: 'response'
  });
}
public delCredit(clientid: number, newUt: number): Observable<HttpResponse<any>> {
  return this.http.get(this.clientUrl + '/' + 'cutcredit/' + clientid + '/' + newUt,{
    observe: 'response'
  });
}

  public createClient(client: Client, revendeurid: number): Observable<HttpResponse<any>> {
    return this.http.post(this.clientUrl + '/' + 'add' + '/' + revendeurid, client, {
      observe: 'response'
    });
  }

  public updateClient(client: Client): Observable<HttpResponse<any>> {
    return this.http.put(this.clientUrl + '/' + 'update/'+ client.clientid, client, {
      observe: 'response'
    });
  }

  public deleteClient(client: Client): Observable<HttpResponse<any>> {
    // this.logger.trace('id', client.clientid);
    return this.http.delete(this.clientUrl + '/' + 'delete/' + client.clientid, {
      observe: 'response'
    });
  }
  // https://kyrmanpay.com:9097/coresmsmarketing/achat/getamount/{amount}
  public getComMontant(montant:number): Observable<HttpResponse<any>>  {
    return this.http.get(this.configService.get('ip') + 'achat/getamount/' + montant,{
      observe: 'response'
    });
  }


  public getIndicatifs(): Observable<HttpResponse<any>>  {
    return this.http.get(this.configService.get('ipIndicatifs'),{
      observe: 'response'
    });
  }






}
