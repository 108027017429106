import { HttpModule } from "@angular/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import {
  BrowserModule,
  HAMMER_GESTURE_CONFIG,
} from "@angular/platform-browser";
import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from "@angular/platform-browser/animations";

import { GestureConfig, MAT_DATE_LOCALE } from "@angular/material";
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";

import { rootRouterConfig } from "./app.routing";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";

import { UserIdleModule } from "angular-user-idle";

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  registerLocaleData,
  HashLocationStrategy,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
import localeFr from "@angular/common/locales/fr";
registerLocaleData(localeFr);
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { NgxHopscotchModule } from "ngx-hopscotch";
import { ConfigModule } from "ngx-envconfig";
import { environment } from "environments/environment.prod";
import { ServiceWorkerModule } from "@angular/service-worker";
import { RequestPaiementComponent } from "./views/paiement/request-paiement/request-paiement.component";

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

@NgModule({
  imports: [
    // Ajouts
    UserIdleModule.forRoot({ idle: 600, timeout: 1, ping: 60 }),
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    PerfectScrollbarModule,

    // ngx-envconfig
    // ConfigModule.forRoot({state: 'developpement'}),
    ConfigModule.forRoot({ state: environment.state }),

    NgxHopscotchModule.forRoot(
      {
        id: "prestige-sms",
        showPrevButton: true,
      },
      {
        id: "prestige-sms-tour",
        target: "tour",
        placement: "top",
        content:
          "Bienvenue sur votre plateforme specialisé dans l'envoi de bulk SMS.",
        title: "Bienvenue sur SMS MARKETING",
      }
    ),

    HttpClientModule,
    HttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // Logger
    // LoggerModule.forRoot({serverLoggingUrl: environment.serverLoggingUrl, level: NgxLoggerLevel.TRACE , serverLogLevel: NgxLoggerLevel.ERROR}),
    LoggerModule.forRoot({
      level:
        environment.state === "production"
          ? NgxLoggerLevel.TRACE
          : environment.state === "developpement"
          ? NgxLoggerLevel.TRACE
          : NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    // InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    RouterModule.forRoot(rootRouterConfig, { useHash: false }),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  declarations: [
    AppComponent,
    RequestPaiementComponent,
    RequestPaiementComponent,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    // {provide: LocationStrategy, useClass: PathLocationStrategy},
    {
      provide: LOCALE_ID,
      useValue: "fr-FR",
    },
    {
      provide: MAT_DATE_LOCALE,
      useExisting: LOCALE_ID,
    },
  ],
  entryComponents: [],

  bootstrap: [AppComponent],
})
export class AppModule {}
