import { ConfigService } from 'ngx-envconfig';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Root} from '../models/root.model';
import { Revendeur } from '../models/revendeur';
import { Client } from '../models/client.model';
import { Observable } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { Connexion } from '../models/connexion.model';


const httpOptions = {
  headers: new HttpHeaders({'Access-Control-Allow-Origin' : '*'})
    .set('content-Type' , 'application/json')
};


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private ulrforgetrootbylogin = 'http://ip:port';
  private  ulrforverifyroot = this.configService.get('ip') + 'root/login';
  private  ulrforverifyrevendeur = this.configService.get('ip') + 'revendeur/login';
  private  ulrforverifyclient = this.configService.get('ip') + 'client/login';

  constructor(private http: HttpClient,
    private configService: ConfigService,
    private logger: NGXLogger) { }


  public getRoot(connexion: Connexion): Observable<HttpResponse<any>> {
    return this.http.post(this.ulrforverifyroot , connexion, {
      observe: 'response',
      headers: new HttpHeaders({'Access-Control-Allow-Origin' : '*'})
      .set("content-Type" , "application/json")
    });
  }

  public getRevendeur(connexion: Connexion): Observable<HttpResponse<any>> {
    return this.http.post(this.ulrforverifyrevendeur , connexion, {
      observe: 'response'
    });
  }

  public getClient(connexion: Connexion): Observable<HttpResponse<any>> {
    return this.http.post(this.ulrforverifyclient , connexion, {
      observe: 'response'
    });
  }



  addRootToLocalStorage(root : Root){
    // console.log('FONCTION');
    if(localStorage.getItem('SMSMarketing_currentRoot')!=null){

      localStorage.removeItem('SMSMarketing_currentRoot');
      localStorage.setItem('SMSMarketing_currentRoot',JSON.stringify(root));
      // console.log(localStorage.getItem('SMSMarketing_currentRoot'));
      this.logger.trace(localStorage.getItem('SMSMarketing_currentRoot'));
    }else{

      localStorage.setItem('SMSMarketing_currentRoot',JSON.stringify(root));
    }
  }

  addClientTourToLocalStorage(tour: boolean) {
    if(localStorage.getItem('SMSMarketing_currentCliTour')!=null){
      localStorage.removeItem('SMSMarketing_currentCliTour');
      localStorage.setItem('SMSMarketing_currentCliTour', JSON.stringify(tour));
    }else{
      localStorage.setItem('SMSMarketing_currentCliTour', JSON.stringify(tour));
    }

}

  removeRootToLocalStorage(){
    localStorage.removeItem('SMSMarketing_currentRoot');
  }

  addRevendeurToLocalStorage(rev: Revendeur) {
      localStorage.setItem('SMSMarketing_currentRev', JSON.stringify(rev));
  }

  addClientToLocalStorage(cli: Client) {
      localStorage.setItem('SMSMarketing_currentCli', JSON.stringify(cli));
  }

  removeRevToLocalStorage() {
    localStorage.removeItem('SMSMarketing_currentRev');
  }

  removeCliToLocalStorage(){
    localStorage.removeItem('SMSMarketing_currentCli');
  }


  removeCliTourToLocalStorage(){
    localStorage.removeItem('SMSMarketing_currentCliTour');
  }







}
