import { Component, OnInit, Input } from '@angular/core';
import { ConfigService } from 'ngx-envconfig';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-contactclient',
  templateUrl: './contactclient.component.html',
  styleUrls: ['./contactclient.component.scss']
})
export class ContactclientComponent implements OnInit {

  isCustomizerOpen: boolean = false;
  private loginRev = '';

  @Input()
  set revLogin(revLogin: string) {
    this.loginRev = (revLogin) || 'AUTRE';
  }

  get revLogin(): string {
     return this.loginRev; }

  constructor(
    private configService: ConfigService,
    private logger: NGXLogger
  ) {}

  ngOnInit() {

  }

  telechargerDoc() {

    if ( this.revLogin.toUpperCase() === this.configService.get('loginKyrmann').toUpperCase()) { // Documentation si client venant du revendeur KYRMANN (Client particulier)
      window.open(this.configService.get('urlDocClientParticulier'));

    } else {
      window.open(this.configService.get('urlDocClient'));

    }

  }


}
